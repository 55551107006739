/**
 * @file
 */

(function ($) {
  $(window).once().on('load', function () {
    $('.get-popup').on('click', function (e) {
      e.preventDefault();
      $(this).next('.tool').toggleClass('d-none');
      var $el = $(this).next('.tool');
      if ($(this).parents('.column__second').length > 0 && $(window).width() >= 992) {
        $el.css('left', ($(this).position().left + 30) * -1);
      }
      else {
        $el.css('left', $(this).position().left + $(this).width() - 20);
      }
      
      showToolOnViewport($el);

      $(window).on('resize', function () {
        showToolOnViewport($el);
      });
    });

    function showToolOnViewport($el) {
      var $window = $(window);
      $el.removeClass('tool__viewport');

      if ($el[0].getBoundingClientRect().bottom > $window.innerHeight()) {
        $el.addClass('tool__viewport');
      } else {
        $el.removeClass('tool__viewport');
      }
    }

    $(document).mouseup(function (e) {
      var container = $('.tool');
      if (!container.is(e.target)
        && container.has(e.target).length === 0) {
        container.addClass('d-none');
      }
    });

    $('.tool code').on('click', function () {
      copyToClipboard(this);
      copiedTooltip(this);
    });

    $('.bundles-commands code').on('click', function () {
      copyToClipboard(this);
      copiedTooltip(this);
    });

    function copiedTooltip(element) {
      var $span = $(document.createElement('span'));
      $(element).append($span);
      $span.html('Copied');
      setTimeout(function () {
        $span.remove();
      }, 1000);
    }

    function copyToClipboard(element) {
      var $temp = $("<input>");
      $("body").append($temp);
      $temp.val($(element).text()).select();
      document.execCommand("copy");
      $temp.remove();
    }

  });
})(jQuery);
